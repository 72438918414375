import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { Roles, IsCompany, IsProfileCompleted } from "../../shared/enum";
import { SidebarService } from "../../services/sidebar/sidebar.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean = false;
  errMsg: string;
  passwordHidden: boolean = false;
  isApiWorked: boolean = false;
  
  @ViewChild("emailIn", { static: true }) emailInVala;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _authService: AuthService,
    private _localStorageService: LocalStorageService,
    private sidebarService: SidebarService
  ) { }

  ngOnInit() {
    let bodyclass = document.getElementsByTagName("body");
    bodyclass[0].classList.add("bg-gradient-primary");
    const checkUser = this._localStorageService.getObjectsValueByKeyNameOrFullInfo('userInfo');
    const adminRoleInfo = this._localStorageService.getObjectsValueByKeyNameOrFullInfo(
      "userInfo",
      "role"
    );
    if (checkUser) {
      const isDeleteAccount = this._localStorageService.getObjectsValueByKeyNameOrFullInfo('isDeleteAccount');
      if(isDeleteAccount){
        this.router.navigate(['/user']);
      } else {
        if(adminRoleInfo.slug === Roles.SUPER_ADMIN){
          this.router.navigate(['/home']);
        } else if(adminRoleInfo.slug === Roles.USER && checkUser.isCompany === IsCompany.APPROVED) {
          if(checkUser.isProfileCompleted === IsProfileCompleted.NO){
            this.sidebarService.isProfileCompleted = false;
            localStorage.setItem("isProfileCompleted", JSON.stringify(this.sidebarService.isProfileCompleted));
            this.router.navigate(['/company/profile-setup']);
          } else if(checkUser.isProfileCompleted === IsProfileCompleted.YES){
            this.sidebarService.isProfileCompleted = true;
            localStorage.setItem("isProfileCompleted", JSON.stringify(this.sidebarService.isProfileCompleted));
            this.router.navigate(['/company/dashboard']);
          }
          
        }
      }
    }
    this.formLoad();
  }
  formLoad() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  login() {
    this.submitted = true;
    this.errMsg = undefined;
    if (this.loginForm.invalid) return true;
    this.isApiWorked = true;

    this._authService.adminLogin(this.loginForm.value).subscribe(
      (res: any) => {
        this.isApiWorked = false;
       
        if (
          res !== undefined &&
          res !== null &&
          res !== "" &&
          res.response !== "" &&
          res.response !== null
        ) {
          this._localStorageService.setData(
            JSON.stringify(res.response.userInfo),
            JSON.stringify(res.response.deviceInfo),
            JSON.stringify(res.response.companyInfo)
          );

          this.router.navigateByUrl("/home");

          if(res.response.userInfo.role.slug === Roles.SUPER_ADMIN){
            this.router.navigate(['/home']);
          } else if(res.response.userInfo.role.slug === Roles.USER && res.response.userInfo.isCompany === IsCompany.APPROVED) {
            this._localStorageService.setItem( 'companyInfo',
              JSON.stringify(res.response.companyInfo)
            );
            if(res.response.userInfo.isProfileCompleted === IsProfileCompleted.NO){
              this.sidebarService.isProfileCompleted = false;
              localStorage.setItem("isProfileCompleted", JSON.stringify(this.sidebarService.isProfileCompleted));
              this.router.navigate(['/company/profile-setup']);
            } else if(res.response.userInfo.isProfileCompleted === IsProfileCompleted.YES){
              this.sidebarService.isProfileCompleted = true;
              localStorage.setItem("isProfileCompleted", JSON.stringify(this.sidebarService.isProfileCompleted));
              this.router.navigate(['/company/dashboard']);
            }
            
          }
          
        } else {
          this.errMsg = res.errorMsg;
        }
      },
      (err) => {
        this.isApiWorked = false;
        throw err;
      }
    );
  }

  showPassword() {
    this.passwordHidden = !this.passwordHidden;
  }

  get loginF() {
    return this.loginForm.controls;
  }
}
