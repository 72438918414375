// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "http://192.168.1.11:5005/api",   //"http://139.162.53.200:5005/api",
  // apiUrl: "http://106.201.228.100:5005/api",
  apiUrl: "https://api.ottlo.com:5000/api",
  localStoragePassword: "Ottlo@123",
  firebaseConfig: {
    apiKey: "AIzaSyC30XjIPe1YRxEPMvfu0WbX0LOUxrcgPXE",
    authDomain: "ottla-aff92.firebaseapp.com",
    projectId: "ottla-aff92",
    storageBucket: "ottla-aff92.appspot.com",
    messagingSenderId: "924675540825",
    appId: "1:924675540825:web:ca0b0a2abef57794c22e48",
    measurementId: "G-LJK14WS45C"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
