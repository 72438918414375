import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentMessage = new BehaviorSubject<any>(null);

  constructor(private apiService: ApiService, private afMessaging: AngularFireMessaging) { 
    this.requestPermission()
    this.listenForMessages()
  }
  deviceToken: any
  requestPermission() {
    // Request permission to send push notifications

    return this.afMessaging.requestToken.subscribe(
      (token) => {
        this.deviceToken =   token;
      },
      (error) => {
        console.error('Unable to get permission to notify.', error);
      }
    );

  }

  adminLogin(data: any): Observable<any> {
    const path = `/adminLogin`;
    const obj = {
      devicePlatform: "web",
      deviceUniqueId: "webadmin",
      deviceToken: this.deviceToken,
      deviceModel: "web",
      os: "ubuntu",
    };
    
    Object.assign(data, obj);

    return this.apiService.postRequest(path, data);
  }

  listenForMessages() {
    this.afMessaging.messages
      .subscribe((message) => {
        this.currentMessage.next(message);
      });
  }

  forgetPassword(data: any) {
    const path = `/forgotPassword`;
    return this.apiService.postRequest(path, data);
  }
  checkResetToken(data: any) {
    const path = `/checkResetToken`;
    return this.apiService.postRequest(path, data);
  }

  resetPassword(data: any) {
    const path = `/resetPassword`;
    return this.apiService.postRequest(path, data);
  }
  changePassword(data: any) {
    const path = `/changePassword`;
    return this.apiService.postRequest(path, data, true);
  }

  login(data: any): Observable<any> {
    const path = `/login`;
    const obj = {
      devicePlatform: "web",
      deviceUniqueId: "webadmin",
      deviceToken:
        "APA91bHjsWaDEq8ASKK5DRzB9GBsH7Z5sasdsadsadsadsadsar965674857YP_CCMm4m87Zw7RujdTV6m525S6U9ssEt_84dTqu0GuAFDbXXUmAhCK63JjnMiA0fPtC1cdsadasdsaderer",
      deviceModel: "web",
      os: "ubuntu",
    };
    Object.assign(data, obj);

    return this.apiService.postRequest(path, data);
  }
}
