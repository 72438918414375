export enum Roles {
    SUPER_ADMIN = 'super_admin',
    USER = 'user',
}

export enum IsCompany {
  NOT_APPROVED = 0,
  APPROVED = 2
}

export enum IsProfileCompleted {
  NO = 0,
  YES = 1
}
export enum YesNo{
  NO = 0,
  YES = 1
}

export enum CompanyDriverStatus{
  INACTIVE = 0,
  ACTIVE = 1,
  INVITATION_SENT = 2,
  ONBORDING = 3,
  ONBORDED = 4,
  INJOB = 5,
  ONLINE = 6,
  OFFLINE = 7
}


export enum CompanyOrderStatus{
  PENDING_ORDER = 0,
  PREPARING_ORDER = 2,   // Ready to deliver
  READY_ORDER = 3,       // Picked up(On the way for delivery) 
  COMPLETED = 4,         // Order delivered.
  CANCEL_ORDER = 5,      // Cancel order
}

export enum DeliveryType {
  FOOD  = 0, // Now its Document.
  MARKET_PLACE = 1 // Now its Parcel
}