import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './modules/login/login.module';
import { ForgetPasswordModule } from './modules/forget-password/forget-password.module';
import { MainModule } from './modules/main/main.module';
import { ResetPasswordModule } from './modules/reset-password/reset-password.module';
import { HttpClientModule } from "@angular/common/http";
import { GlobalErrorHandlerService } from './shared/global-error-handler.service';
import { LocalStorageService } from './shared/local-storage.service';
import { ApiService } from './services/api.service';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/messaging';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoginModule,
    ForgetPasswordModule,
    ResetPasswordModule,
    MainModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireMessagingModule

  ],
  providers: [GlobalErrorHandlerService, AngularFireMessaging,
    ApiService,
    LocalStorageService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },],
  bootstrap: [AppComponent]
})
export class AppModule { }
